import { ShopColors } from "@/utils/types";

export enum ColorProperty
{
	background = 'bg',
	border = 'border',
	text = 'text',
	gradient = 'from',
}

/**
 * @deprecated getColor() should be used
 */
export const getBgColor = (value: string, defaults?: Partial<ShopColors>, shade: number = 500): string =>
{
	if (defaults && defaults !== undefined)
	{
		return getColor(
			ColorProperty.background,
			value,
			defaults);
	}
	return getColor(
		ColorProperty.background,
		value,
		shade)
}

/**
 * @deprecated getColor() should be used
 */
export const getTextColor = (value: string, defaults?: Partial<ShopColors>, shade: number = 500): string =>
{
	if (defaults && defaults !== undefined)
	{
		return getColor(
			ColorProperty.text,
			value,
			defaults);
	}
	return getColor(
		ColorProperty.text,
		value,
		shade)
}

/**
 * @deprecated getColor() should be used
 */
export const getBorderColor = (value: string, defaults?: Partial<ShopColors>, shade: number = 500): string =>
{
	if (defaults && defaults !== undefined)
	{
		return getColor(
			ColorProperty.border,
			value,
			defaults);
	}
	return getColor(
		ColorProperty.border,
		value,
		shade)
}

export function getColor(property: ColorProperty, value: string, defaults: Partial<ShopColors>): string;

export function getColor(property: ColorProperty, value: string, tint: number): string;

export function getColor(property: ColorProperty, value: string, defaultsOrTint: Partial<ShopColors> | number): string
{

	// Default
	if (defaultsOrTint !== undefined && typeof defaultsOrTint !== 'number')
	{
		// Custom color in default
		if (value !== undefined && value.includes('custom'))
		{
			const _defaults = (defaultsOrTint as Partial<ShopColors>)['custom' as keyof ShopColors];
			if (_defaults && _defaults !== undefined)
			{

				return `${property}-${value}-500 ` + _defaults;
			}
		}
		// Primary, Secondary or Neutral in default
		if (value !== undefined)
		{
			const _defaults = (defaultsOrTint as Partial<ShopColors>)[value as keyof ShopColors];
			if (_defaults && _defaults !== undefined)
			{
				return _defaults;
			}
		}
	}
	// White
	if (value !== undefined && value === 'unset')
	{
		return `${property}-white`;
	}
	// Tint
	if (typeof (defaultsOrTint) === 'number')
	{
		// In case tint substructs dynamicaly and the lowest Tailwind value, '50', is selected
		if (defaultsOrTint < 50)
		{
			return `${property}-${value}-50`;
		}
		// In case tint summurise dynamicaly and the highest Tailwind value, '950', is selected
		if (defaultsOrTint > 950)
		{
			return `${property}-${value}-950`;
		}
		// In case tint calculates dynamically and the result is not proportional to 100 
		// and not suitable to Tailwind midrange tints.
		if (defaultsOrTint % 100 !== 0)
		{
			return `${property}-${value}-${Math.floor(defaultsOrTint / 100) * 100}`;
		}
	}
	// Default fallback
	return `${property}-${value}-${defaultsOrTint}`;

}
