'use client';

import FAIcon from "@/base-components/icons";
import { DsxImageClient } from "@/base-components/image";
import { special } from "@/types/graphql";
import { DsxImageType } from "@/utils/types";
import Link from "next/link";

function printCard(id: string)
{
	if (document)
	{
		let printContents = document.getElementById(id)?.innerHTML;
		let originalContents = document.body.innerHTML;

		printContents && (document.body.innerHTML = printContents)

		window.print();

		document.body.innerHTML = originalContents;
	}
}

export default function CardSpecial({ key, special, image, image_desaturated, styles }: { key: number, special: special, image?: DsxImageType, image_desaturated?: DsxImageType, styles: { image: string, content: string, title: string } })
{

	return (
		<>

			{/* Special image */}
			{
				image &&
				<div className={`${styles['image']}
				flex aspect-[4/3] w-auto relative bg-primary-50 rounded overflow-hidden`}>

					<DsxImageClient dsxImage={image} />
					{
						image_desaturated &&
						<DsxImageClient dsxImage={image_desaturated} />
					}

				</div>
			}

			{/* Special content */}
			<div className={`${styles['content']}
			flex flex-col justify-between w-full h-full px-6 py-8`}>

				{/* Special date */}
				<p className="mb-2 text-right">
					{special?.end_date
						? 'Until ' + new Date(special?.end_date)?.toLocaleDateString('en-US')
						: 'Permanent'}
				</p>

				{/* Special title */}
				<h2 className={`${styles?.title}
					mb-2 text-h5 dark:text-white`}>

					{special?.title}

				</h2>

				{/* Special text */}
				{
					special?.description &&
					<div className={`mb-8 line-clamp-8`}>

						<div dangerouslySetInnerHTML={{ __html: special?.description }} />

					</div>
				}

				{/* Special primary button */}
				<div className="mt-auto mb-2 flex w-full font-medium text-primary-500 hover:text-primary-600">
					<Link
						href="#make-appointment"
						className="inline-flex text-center justify-center items-center w-full rounded-xs border border-primary-500 hover:border-primary-600 px-6 py-3 focus:outline-none focus:ring cursor-pointer">

						Request Appointment

						<FAIcon type="solid" nameIco='fa-arrow-right' customClass="w-4 h-4 ml-2" />

					</Link>
				</div>

				{/* Special print button */}
				<div className="mb-4 flex w-full text-primary-500 hover:text-primary-600">
					<button
						onClick={() => printCard('special_card-' + key)}
						className="inline-flex text-center justify-center items-center w-full rounded-xs px-6 py-3 font-medium focus:outline-none focus:ring cursor-pointer">

						Print Coupon

						<FAIcon type="solid" nameIco='fa-print' customClass="w-4 h-4 ml-2" />

					</button>
				</div>

				{/* Special fineprint */}
				{
					special?.fine_print &&
					<p className="w-full text-p5">
						{special?.fine_print}
					</p>
				}

			</div>

		</>
	)

}