"use client";

import FAIcon from "@/base-components/icons";
import { shop } from "@/types/graphql";
import { getColor, ColorProperty } from "@/utils/color";
import { stdComponentDataList } from "@/utils/std-component-data";
import React, { useRef, useState } from 'react';

//-->dynamicData
let dynamicData = {
	uid: 'faq-accordion',
	adapter: "faq",
	data: {

		// Content
		"overtitle": {
			sort: 1,
			name: 'Overtitle',
			type: 'text',
			value: '',
			section: 'content',
		},
		"title": {
			sort: 2,
			name: 'Title',
			type: 'text',
			value: 'FAQ',
			section: 'content',
		},
		"description": {
			sort: 3,
			name: 'Description',
			type: 'textarea',
			value: 'Answered all frequently asked questions, Still confused? Feel free to contact us.',
			section: 'content',
		},

		// UI
		"section_bg_color": {
			sort: 1,
			name: 'Section Background Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 100, 'ui', 'tints'),

		"faq_bg_color": {
			sort: 3,
			name: 'FAQ Background Color',
			type: 'color',
			value: 'primary',
			section: 'ui',
		},
		"faq_bg_color_tint": stdComponentDataList('slider', 4, 'FAQ Background Color Tint', 'FAQ Background Color Tint', '', 50, 'ui', 'tints'),

		"faq_text_color": {
			sort: 5,
			name: 'FAQ Text Color',
			type: 'color',
			value: 'neutral',
			section: 'ui',
		},
		"faq_text_color_tint": stdComponentDataList('slider', 6, 'FAQ Text Color Tint', 'FAQ Background Color Tint', '', 600, 'ui', 'tints'),

		"shadow": {
			sort: 7,
			name: 'Shadow',
			type: 'checkbox',
			value: 'true',
			section: 'ui',
		},

		"padding_top": stdComponentDataList('slider', 8, 'Top Padding', 'Top Padding', 'pt', 20),
		"padding_bottom": stdComponentDataList('slider', 9, 'Bottom Padding', 'Bottom Padding', 'pb', 20),
	},
}
//<--dynamicData

const FaqCard = ({ faqsList, idx }: { faqsList: any, idx: number }) =>
{

	const answerElRef = useRef<HTMLDivElement>(null);
	const [state, setState] = useState(false)
	const [answerH, setAnswerH] = useState('0px')

	const handleOpenAnswer = () =>
	{
		const answerElH = (answerElRef?.current?.children[0] as HTMLDivElement).offsetHeight;
		setState(!state)
		setAnswerH(`${answerElH + 20}px`)
	}

	return (
		faqsList?.enabled === true &&
		<div
			className="space-y-3 mt-5 overflow-hidden border-b"
			key={idx}
			onClick={handleOpenAnswer}>

			<h4 className={`${getColor(
				ColorProperty.text,
				dynamicData?.data?.faq_text_color?.value,
				Number(dynamicData?.data?.faq_text_color_tint?.value) + 300)}
				flex pb-4 items-center justify-between font-bold cursor-pointer`}>

				{faqsList?.question}

				{
					state
						?
						<FAIcon type="solid" nameIco='fa-minus' customClass="w-4 h-4 ml-2" />
						:
						<FAIcon type="solid" nameIco='fa-plus' customClass="w-4 h-4 ml-2" />
				}

			</h4>

			<div
				ref={answerElRef} className="duration-300"
				style={state ? { height: answerH } : { height: '0px' }}>

				<p className="">
					{faqsList?.answer}
				</p>

			</div>

		</div>
	)
}

export default function Faq({ data, config }: { data: shop, config: any })
{

	config?.uid && (dynamicData = config);

	return (
		<section className={`
			${getColor(
			ColorProperty.background,
			dynamicData?.data?.section_bg_color?.value,
			dynamicData?.data?.section_bg_color_tint?.value)}
			${dynamicData?.data?.padding_top?.value} 
			${dynamicData?.data?.padding_bottom?.value}
			px-4 md:px-6 lg:px-8 xl:px-0`}>

			<div className={`
				${getColor(
				ColorProperty.background,
				dynamicData?.data?.faq_bg_color?.value,
				dynamicData?.data?.faq_bg_color_tint?.value)}
				${getColor(
					ColorProperty.text,
					dynamicData?.data?.faq_text_color?.value,
					dynamicData?.data?.faq_text_color_tint?.value)}
				relative px-8 py-20 lg:w-2/3 mx-auto rounded`}>

				{/* Shadow */}
				{
					dynamicData?.data?.shadow?.value === 'true' &&
					<div className={`
						${dynamicData?.data?.section_bg_color?.value === 'unset' ||
							dynamicData?.data?.section_bg_color?.value === 'neutral'
							?
							'opacity-50'
							:
							'mix-blend-overlay'}
							absolute flex inset-0 rounded shadow`}
					/>
				}

				<div className="relative">

					{/* Header */}
					<div className="space-y-3 max-w-2xl mx-auto">

						{/* Overtitle */}
						{
							dynamicData?.data?.overtitle?.value &&
							<p className="text-p5 mb-4 w-full font-bold uppercase tracking-widest line-clamp-1">

								{dynamicData?.data?.overtitle?.value}

							</p>
						}

						{/* Title */}
						{
							dynamicData?.data?.title?.value &&
							<h2 className={`${getColor(
								ColorProperty.text,
								dynamicData?.data?.faq_text_color?.value,
								Number(dynamicData?.data?.faq_text_color_tint?.value) + 300)}
							font-bold capitalize tracking-tight`}>

								{dynamicData?.data?.title?.value}

							</h2>
						}

						{/* Description */}
						{
							dynamicData?.data?.description?.value &&
							<p className={`text-p2`}>

								{dynamicData?.data?.description?.value}

							</p>
						}

					</div>

					{/* Cards */}
					<div className="mt-14 max-w-2xl mx-auto">
						{
							data?.faqs?.map((item: any, idx: any) => (
								<FaqCard
									key={'faq-' + idx}
									idx={idx}
									faqsList={item}
								/>
							))
						}
					</div>

				</div>

			</div>

		</section>
	)
}
