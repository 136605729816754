import { getColor, ColorProperty } from "@/utils/color";
import Link from "next/link";

export enum ButtonType
{
	button = 'button',
	submit = 'submit',
	reset = 'reset',
}

export enum ButtonStyle
{
	filled = 'filled',
	outlined = 'outlined',
	link = 'link',
	text = 'text',
}

export enum ButtonSize
{
	small = 'small',
	regular = 'regular',
	large = 'large',
}

export enum ButtonTarget
{
	blank = '_blank',
	self = '_self',
	parent = '_parent',
	top = '_top',
	framename = 'framename',
}

interface ButtonProps
{
	id: string;
	href?: string;
	type?: ButtonType;
	style: ButtonStyle;
	size?: ButtonSize;
	target?: ButtonTarget;
	color: string;
	tint: number;
	children?: React.ReactNode;
	onClick?: () => void;
}

export function Button({ id, href, type = ButtonType.button, style, size = ButtonSize.regular, target = ButtonTarget.self, color, tint, children, onClick }: ButtonProps)
{

	const _textColor = getTextColor(style, color, tint);
	const _style = getStyle(style, color, tint);
	const _size = getSize(size, style);

	const className = [
		_style,
		_textColor,
		_size,
		'inline-flex items-center justify-center text-center rounded font-medium whitespace-nowrap duration-300 focus:outline-none focus:ring cursor-pointer'
	].filter(Boolean).join(' ');

	return (
		href ?
			<Link
				id={id}
				href={href}
				target={target}
				className={className}
				onClick={onClick}>

				{children}

			</Link>
			:
			<button
				id={id}
				type={type}
				className={className}
				onClick={onClick}>

				{children}

			</button>
	)

	function getTextColor(style: ButtonStyle, color: string, tint: number)
	{
		switch (style)
		{
			case ButtonStyle.filled:
				return color !== 'unset'
					? 'text-white'
					: getColor(ColorProperty.text, 'primary', 500);
			case ButtonStyle.outlined:
				return getColor(ColorProperty.text, color, tint);
			case ButtonStyle.link:
				return '';
			case ButtonStyle.text:
				return '';
			default:
				return 'text-white';
		}
	}

	function getStyle(style: ButtonStyle, color: string, tint: number)
	{
		switch (style)
		{
			case ButtonStyle.filled:
				return `${getColor(ColorProperty.background, color, tint)}
				hover:${getColor(ColorProperty.background, color, Number(tint) + 100)}`;
			case ButtonStyle.outlined:
				return `${getColor(ColorProperty.border, color, tint)}
				hover:${getColor(ColorProperty.border, color, Number(tint) + 100)}
				border`;
			case ButtonStyle.link:
				return `${getColor(ColorProperty.text, color, tint)}
				hover:${getColor(ColorProperty.text, color, Number(tint) + 100)}
				underline underline-offset-4 hover:underline-offset-8 duration-300`;
			case ButtonStyle.text:
				return `${getColor(ColorProperty.text, color, tint)}
				hover:${getColor(ColorProperty.text, color, Number(tint) + 100)}
				underline underline-offset-4 hover:underline-offset-8 duration-300`;
			default:
				return 'bg-primary-500 hover:bg-primary-600';
		}
	}

	function getSize(size: ButtonSize, style: ButtonStyle)
	{
		const isLink = style === ButtonStyle.link

		switch (size)
		{
			case ButtonSize.small:
				return isLink ? 'text-p5' : 'px-6 py-2 text-p5';
			case ButtonSize.regular:
				return isLink ? '' : 'px-8 py-3';
			case ButtonSize.large:
				return isLink ? 'text-p3' : 'px-10 py-4 text-p3';
			default:
				return '';
		}
	}
}
