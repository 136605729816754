'use client';

import { review, shop } from "@/types/graphql";
import Rating from "@/ui-components/rating";
import { stdComponentDataList } from "@/utils/std-component-data";
import { useState } from "react";

//-->dynamicData
let dynamicData = {
	uid: 'legacy-pages-reviews',
	adapter: "reviews",
	data: {

		// Content
		"title": {
			sort: 1,
			name: 'Title',
			type: 'text',
			value: 'Reviews',
			section: 'content',
		},
		"all_reviews": {
			sort: 2,
			name: 'Show All Reviews',
			type: 'checkbox',
			value: 'true',
			section: 'content',
		},
		"reviews_number": {
			sort: 3,
			name: 'Reviews Number',
			type: 'number',
			value: 128,
			min_num: 1,
			section: 'content'
		},

		// UI
		"title_case": {
			sort: 1,
			name: 'Title Letter Case',
			type: 'radio',
			value: 'capitalize tracking-tight',
			options: [
				{
					'display': "Capitalize",
					'value': 'capitalize tracking-tight'
				},
				{
					'display': "Uppercase",
					'value': 'uppercase tracking-wide'
				},
			],
			section: 'ui'
		},
		"title_weight": {
			sort: 2,
			name: 'Title Font Wieght',
			type: 'radio',
			value: 'font-bold',
			options: [
				{
					'display': "Regular",
					'value': ''
				},
				{
					'display': "Bold",
					'value': 'font-bold'
				},
				{
					'display': "Black",
					'value': 'font-black'
				},
			],
			section: 'ui'
		},
		"title_italic": {
			sort: 3,
			name: 'Italic Title',
			type: 'checkbox',
			value: 'false',
			section: 'ui',
		},

		"padding_top": stdComponentDataList('slider', 4, 'Top Padding', 'Padding', 'pt', 24),
		"padding_bottom": stdComponentDataList('slider', 5, 'Bottom Padding', 'Padding', 'pb', 32),
	}
}
//<--dynamicData

export default function PageReviews({ data, config }: { data: shop, config: any })
{
	if (config && config.uid)
	{
		dynamicData = config;
	}

	const [state, setState] = useState('google');

	const av_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review?.source_id === 1;
	})

	const google_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review?.source_id === 2;
	})

	let reviews;

	switch (state)
	{
		case 'av': {
			reviews = av_reviews;
			break;
		}
		case 'google': {
			reviews = google_reviews;
			break;
		}
		default: {
			reviews = google_reviews;
			break;
		}
	}

	const title_styles = [
		dynamicData?.data?.title_case?.value,
		dynamicData?.data?.title_weight?.value,
		dynamicData?.data?.title_italic?.value === 'true' && 'italic',
	].filter(Boolean).join(' ');

	return (
		<section
			id={dynamicData?.uid}
			className="">

			<div className={`
				${dynamicData?.data?.padding_top?.value || 'pt-24'} 
				${dynamicData?.data?.padding_bottom?.value || 'pb-32'} 
    		px-4 sm:px-6 xl:px-0 mx-auto max-w-screen-xl`}>

				<div className="flex flex-wrap justify-between items-start">

					{/* Page title */}
					{
						dynamicData?.data?.title?.value &&
						<h1 className={`${title_styles}`}>

							{dynamicData?.data?.title?.value}

						</h1>
					}

					{/* Tab buttons */}
					<div className="flex pt-2 overflow-x-auto overflow-y-hidden border-b border-neutral-200">

						<button
							onClick={() => setState('google')}
							className={`${state === 'google'
								? 'border-primary-500'
								: 'border-neutral-500'}
								inline-flex leading-tight items-center px-4 py-2 -mb-px text-left md:text-center bg-transparent border-b-2 focus:outline-none`}>

							<h2 className={`
								${dynamicData?.data?.title_italic?.value === 'true' && 'italic'}
								${state === 'google'
									? 'text-primary-500'
									: 'text-neutral-600 hover:text-primary-500 duration-300'}
									text-base capitalize`}>

								Reviews from Google

							</h2>

						</button>

						<button
							onClick={() => setState('av')}
							className={`${state === 'av'
								? 'border-primary-500'
								: 'border-neutral-500'}
								inline-flex leading-tight items-center px-4 py-2 -mb-px text-left md:text-center bg-transparent border-b-2 focus:outline-none`}>

							<h2 className={`
							${dynamicData?.data?.title_italic?.value === 'true' && 'italic'}
								${state === 'av'
									? 'text-primary-500'
									: 'text-neutral-600 hover:text-primary-500 duration-300'}
									text-base capitalize`}>

								Certified by AutoVitals

							</h2>

						</button>

					</div>

				</div>

				<ul className="gap-x-8 gap-y-10 mt-8 gap-8 columns-1 sm:columns-2 lg:columns-3 break-inside-avoid-column">
					{
						reviews?.map((review: any, idx: any) =>
						{
							return (

								<li className="rounded overflow-hidden bg-neutral-50 w-full mx-auto mb-12 group sm:max-w-sm relative break-inside-avoid" key={idx}>
									<div className="p-6">

										<blockquote
											className="flex flex-wrap overflow-hidden mx-4 md:mx-0"
											key={idx}>

											<div className="flex flex-col w-full">
												<Rating rating={review?.rating ?? 5} color={""} className="mb-2" />
												{
													state === 'av' &&
													<h3 className={`${title_styles}
													text-base mb-2 leading-snug`}>

														{review?.author_name || 'Unknown Author'}

														{review?.location
															? ' from ' + review?.location
															: ''}

														{review?.date
															? ' on ' + new Date(review?.date)?.toLocaleDateString('en-US')
															: '—'}

														{review?.ymm
															? ' for an auto service of their ' + review?.ymm
															: 'car'
														}

													</h3>
												}
												{
													state === 'google' &&
													<h3 className={`${title_styles}
													text-base mb-2 leading-snug`}>

														{review?.author_name || 'Unknown Author'}

														{review?.date
															? ' on ' + new Date(review?.date)?.toLocaleDateString('en-US')
															: '—'}

													</h3>
												}
											</div>

											<p className="w-full text-neutral-600">
												{review?.content || '—'}
											</p>

										</blockquote>
									</div>

								</li>

							)
						})
					}
				</ul>
			</div>

		</section>
	)
}